<template>
   <div id="box">
      <h1>抑郁症筛查量表问卷</h1>
      <p>
         请仔细阅读每一条目，然后根据最近两个星期以内你的实际感受，选择一个与你的情况最符合的答案。
      </p>

      <h4>1.做事时提不起劲或没有兴趣</h4>
      <el-radio-group v-model="radio">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group>
      <h4>2.感到心情低落, 沮丧或绝望</h4>
      <el-radio-group v-model="radio1">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group>
      <br />
      <h4>3.入睡困难、睡不安或睡得过多</h4>
      <el-radio-group v-model="radio2">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group>
      <br />
      <h4>4.感觉疲倦或没有活力</h4>
      <el-radio-group v-model="radio3">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group>

      <h4>5.食欲不振或吃太多</h4>
      <el-radio-group v-model="radio4">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group>
      <h4>6.感觉自己很糟，或觉得自己很失败，或让自己或家人失望</h4>
      <el-radio-group v-model="radio5">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group>
      <h4>7.对事物专注有困难，例如阅读报纸或看电视时不能集中注意力</h4>
      <el-radio-group v-model="radio6">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group>
      <h4>8.动作或说话速度缓慢到别人已经察觉；或正好相反，烦躁或坐立不安</h4>
      <el-radio-group v-model="radio7">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group>
      <h4>9.有不如死掉或用某总方式伤害自己的念头</h4>
      <el-radio-group v-model="radio8">
         <el-radio label="0">A：没有</el-radio><br />
         <el-radio label="1">B：有几天 </el-radio><br />
         <el-radio label="2">C：一半以上时间 </el-radio><br />
         <el-radio label="3">D: 几乎每天</el-radio><br />
      </el-radio-group><br>
      <el-button type="success"
                 @click="ExamSolutionADD"
                 :disabled="isdisabled">提交</el-button>
   </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
   mounted () {
      this.get();
   },
   updated () {
      // console.log("总分：", this.total);
      // console.log("说明", this.explain);
   },
   methods: {
      async ExamSolutionADD () {
         const data = await axios({
            url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=抑郁症筛查量表PHQ&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=${this.explain}&Review=暂无建议&Section=/&Doctor=/`,
            method: "post",
            data,
         });
         if (data.data.data == true) {
            Message.success("提交成功");
            this.$router.push("/Assessment");
         }
      },
      //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
      get () {
         const patientN = localStorage.getItem("patientN");
         const patientI = localStorage.getItem("patientI");
         this.name = JSON.parse(patientN);
         this.cid = JSON.parse(patientI);
      },
   },
   computed: {
      explain () {
         if (this.total >= 0 && this.total <= 4) {
            return "没有抑郁症";
         }
         if (this.total >= 5 && this.total <= 9) {
            return "可能有轻微忧郁症";
         }
         if (this.total >= 10 && this.total <= 19) {
            return "可能有中度忧郁症";
         }
         if (this.total >= 20 && this.total <= 27) {
            return "可能有重度忧郁症";
         }
      },
      isdisabled () {
         if (
            this.radio == "" ||
            this.radio1 == "" ||
            this.radio2 == "" ||
            this.radio3 == "" ||
            this.radio4 == "" ||
            this.radio5 == "" ||
            this.radio6 == "" ||
            this.radio7 == "" ||
            this.radio8 == ""
         ) {
            return true;
         }
         if (
            this.radio != "" ||
            this.radio1 != "" ||
            this.radio2 != "" ||
            this.radio3 != "" ||
            this.radio4 != "" ||
            this.radio5 != "" ||
            this.radio6 != "" ||
            this.radio7 != "" ||
            this.radio8 != ""
         ) {
            return false;
         }
      },
      total () {
         return (
            this.radio * 1 +
            this.radio1 * 1 +
            this.radio2 * 1 +
            this.radio3 * 1 +
            this.radio4 * 1 +
            this.radio5 * 1 +
            this.radio6 * 1 +
            this.radio7 * 1 +
            this.radio8 * 1
         );
      },
   },
   data () {
      return {
         ETID: "E3",
         isShow: false,
         name: "",
         cid: "",
         radio: "",
         radio1: "",
         radio2: "",
         radio3: "",
         radio4: "",
         radio5: "",
         radio6: "",
         radio7: "",
         radio8: "",
      };
   },
};
</script>

<style lang="scss" scoped>
// @import "./style.scss";
.el-radio {
   margin: 10px;
}
body {
   background: rgb(220, 245, 179);
}
#box {
   width: 100%;
   margin: auto;
   text-align: left;
   margin-left: 30px;
   // background: rgb(206, 245, 175);
}
.label_color {
   color: rgb(202, 44, 207);
}
h1 {
   text-align: center;
}

h4 {
   color: rgb(140, 102, 245);
}
</style>
